/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
// import PostSection from '../components/PostSection'
import Content from '../components/Content'
import Layout from '../components/Layout'

// Export Template for use in CMS preview
const AboutPageTemplate = ({ data }) => {
  const { title, subTitle, featuredImage, body } = data.directus.aboutPage

  return (
    <main className="Home">
      <PageHeader
        large
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      />

      <section className="section">
        <div className="container">
          <Content body={body} />
        </div>
      </section>
      {/* {filteredPosts.length > 0 && (
        <section className="section">
          <div className="container">
            <h2 style={{ color: '#1e4083' }}>This Weeks Artists</h2>
            <PostSection posts={filteredPosts} />
          </div>
        </section>
      )} */}
    </main>
  )
}

// Export Default HomePage for front-end
const AboutPage = ({ data }) => {
  return (
    <Layout meta={data.directus.aboutPage.SEO || false}>
      <AboutPageTemplate data={data} />
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AboutPage {
    directus {
      aboutPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        SEO {
          image {
            id
          }
          title
          description
        }
      }
    }
  }
`
